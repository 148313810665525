import React, { useState } from "react";
import { useQuery } from "react-query";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { isEmpty } from "lodash";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Filters,
  TableComponent,
  MainBox,
  DateFormatter,
  ClaimInfo,
  ClaimStatuses,
  CurrencyDisplay,
} from "@shared/components/lib/index";
import { getRequestUI } from "common-utils/utils/api";
import { DateTime } from "luxon";
import useTheme from "@mui/material/styles/useTheme";
import { getCurrentMondayToNextMonday } from "common-utils/utils/date";

const useStyles = (theme) => ({
  deliveredStatus: {
    background: "#d7ecfa",
  },
  status: {
    padding: "5 10",
    borderRadius: 20,
    background: "#ffd9d4",
    textAlign: "center",
  },
  circleProgress: {
    marginLeft: 12,
  },
  statusCell: {
    minWidth: "120px",
  },
  infoButton: {
    "& svg": {
      color: "#000000",
    },
  },
  filterBlock: {
    padding: 2,
  },
});

const Claims = () => {
  const styles = useStyles(theme);
  const theme = useTheme();
  const { currentWeekMonday, nextWeekMonday } = getCurrentMondayToNextMonday();
  const [selectedStartClaimDateFilter, setSelectedStartClaimDateFilter] =
    useState(currentWeekMonday);
  const [selectedEndClaimDateFilter, setSelectedEndClaimDateFilter] =
    useState(nextWeekMonday);
  const [currentClaim, setCurrentClaim] = useState({});
  const [openClaimsDialog, setOpenClaimsDialog] = useState(false);

  const openInfoClaim = (claim) => {
    setCurrentClaim(claim);
    setOpenClaimsDialog(true);
  };

  const handleCloseInfo = () => {
    setOpenClaimsDialog(false);
  };

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartClaimDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromJSDate(selectedEndClaimDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
  });

  const { error, data, refetch, isLoading } = useQuery(
    ["carrier-claims"],
    async () => {
      const res = await getRequestUI("/carrier-claims", getFilterData());
      return res;
    }
  );

  const claimTableData = {
    headers: [
      "External Id",
      "Date Claim",
      "Date Order",
      "Tracking Code",
      "Parcel Price",
      "Claim Price",
      "Claim Status",
      "Claim Reason",
      "Details",
    ],
    body: [
      (claim) => claim.externalId,
      (claim) => <DateFormatter date={claim.claimDate} format={"dd-MM-yyyy"} />,
      (claim) => <DateFormatter date={claim.orderDate} format={"dd-MM-yyyy"} />,
      (claim) => claim.trackingCode,
      (claim) => <CurrencyDisplay value={claim.price} currency="EUR" />,
      (claim) => <CurrencyDisplay value={claim.claimPrice} currency="EUR" />,
      (claim) => <ClaimStatuses claim={claim} formatting={true} />,
      (claim) => claim.reason,
      (claim) => (
        <Button
          sx={styles.infoButton}
          startIcon={<VisibilityIcon />}
          onClick={() => openInfoClaim(claim)}
        />
      ),
    ],
  };

  return (
    <MainBox isFullWidth={true}>
      <Box component="span" display={isLoading ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <div style={{ padding: 15 }}>
        <Filters
          setTitle={"Claims"}
          selectedStartPickUpDateFilter={selectedStartClaimDateFilter}
          setSelectedStartPickUpDateFilter={setSelectedStartClaimDateFilter}
          selectedEndPickUpDateFilter={selectedEndClaimDateFilter}
          setSelectedEndPickUpDateFilter={setSelectedEndClaimDateFilter}
          hideExport={true}
          dateName={"Date"}
          onApplyFilter={refetch}
        />
      </div>
      {!error && data && (
        <TableComponent
          headers={claimTableData.headers}
          rowData={data}
          cellComponents={claimTableData.body}
        />
      )}
      {!isEmpty(currentClaim) && (
        <>
          <ClaimInfo
            claim={currentClaim}
            isOpenOrderInfo={openClaimsDialog}
            handleCloseInfo={handleCloseInfo}
          />
        </>
      )}
    </MainBox>
  );
};

export default Claims;
