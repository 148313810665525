import { DateTime } from "luxon";

export const formatAddress = (postcode, house, additions, street) => {
  let address = "";
  if (street) address += street + " ";
  if (house) address += house + " ";
  if (additions) address += additions + " ";
  if (postcode) address += postcode + " ";
  return address;
};

export const formatLocationInfo = (name) => {
  let locationName = name + " ";
  return locationName;
};

export const formatPostCode = (postcode) => {
  if (!postcode) {
    return "";
  }
  return postcode.replace(/\s/g, "").toUpperCase();
};

export const formatDateETA = (eta) => {
  return DateTime.fromJSDate(new Date(eta.etaStart)).toFormat(
    "cccc dd/MM/yyyy",
  );
};

export const formatTimeETA = (eta) => {
  const etaStartTime = `${DateTime.fromJSDate(new Date(eta.etaStart)).toFormat(
    "HH:mm",
  )}`;
  if (eta.etaEnd) {
    const etaEndTime = `${DateTime.fromJSDate(new Date(eta.etaEnd)).toFormat(
      "HH:mm",
    )}`;
    return `${etaStartTime} - ${etaEndTime}`;
  }
  return `${etaStartTime}`;
};

export const formatDate = (date) => {
  return DateTime.fromJSDate(date).toFormat("yyyy/MM/dd");
};

export const formatStatusName = (status, statusHistory) => {
  const { reason } = _.head(statusHistory);
  if (status.id === "FAILED_AT_CARRIER") {
    return "Processing";
  }
  if (
    (status.id === "ON_THE_WAY_TO_PICKUP" ||
      status.id === "FAILED_TO_DELIVER") &&
    reason &&
    reason.id === "RETAILER_PARCEL_NOT_READY_AT_PICK_UP"
  ) {
    return "Manco";
  }
  return status.name;
};

export const formatTime = (date, locale = "nl") =>
  DateTime.fromISO(date, { setZone: true }).toFormat("HH:mm", { locale });
