import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import "date-fns";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import FileDownload from "js-file-download";
import { formatAddress } from "common-utils/utils/formatter";
import EditIcon from "@mui/icons-material/Edit";
import {
  getRequestUI,
  putRequestUI,
  postRequestUI,
} from "common-utils/utils/api";
import {
  Filters,
  TableComponent,
  UpdateOrderStatus,
  MainBox,
  ShippingLabelButton,
  DateFormatter,
} from "@shared/components/lib/index";
import { Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { TIME_SHOW_ERROR } from "common-utils/src/constants";
import { notDeliveredReason } from "common-utils/utils/updateOrder";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = (theme) => ({
  deliveryStatus: {
    display: "flex",
    alignItems: "center",
  },
});

function CarrierScheduled(props) {
  const { userType } = props;
  const styles = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentOrder, setCurrentOrder] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date());
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(new Date());
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState([]);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState();
  const [reason, setCurrentReason] = useState({});
  const [selectedZipCodeFilter, setSelectedZipCodeFilter] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);

  const prerequisites = useQuery(
    ["initCarrierOrderPrerequisites"],
    async () => {
      const result = await getRequestUI("/carrier/orders-prerequisites");
      return result;
    }
  );

  useEffect(() => {
    updateTable();
  }, []);

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    locations: selectedLocationsFilter.map((location) => location.id),
    postCode: selectedZipCodeFilter,
  });

  const saveStatus = (remark) => () => {
    setUpdatingStatus(true);
    if (reason && reason.id === notDeliveredReason && !remark) {
      setShowError(true);
      setErrorMessage("Remark is required");
      return;
    }

    const updateStatus = {
      orderIds: selectedOrders.length > 0 ? selectedOrders : [currentOrder.id],
      statusId: currentStatus && currentStatus.id,
      remark,
      reasonId: reason && reason.id,
      timestamp: selectedTime,
    };

    if (!updateStatus.orderIds.length) {
      setErrorMessage("No selected orders for update");
      setShowError(true);
      handleStatusDialogClose();
      return;
    }
    if (!updateStatus.statusId) {
      setErrorMessage("No selected status for update");
      setShowError(true);
      handleStatusDialogClose();
      return;
    }
    return putRequestUI("/carrier/status", updateStatus)
      .then(() => {
        return updateTable();
      })
      .then(() => setStatusDialogOpen(false))
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
      })
      .finally(() => {
        setSelectedOrders([]);
        setUpdatingStatus(false);
      });
  };

  const handleStatusDialogClose = () => {
    setSelectedOrders([]);
    setStatusDialogOpen(false);
  };

  const updateTable = useCallback(() => {
    setShowProgress(true);
    getCarrierOrders();
  });

  const getCarrierOrders = async () => {
    try {
      const response = await getRequestUI(
        "/carrier/scheduled/orders",
        getFilterData()
      );
      setShowProgress(false);
      setOrders(response);
    } catch (e) {
      var code = e.code;
      var message = e.message;
    }
  };

  const getFilteredOrders = () => {
    return orders;
  };

  const onExportClick = async () => {
    try {
      setExportLoading(true);
      const filters = getFilterData();
      const response = await postRequestUI("export-orders/carrier", filters);
      FileDownload(
        response,
        `orders_${filters.startDate}_${filters.endDate}.csv`
      );
      setExportLoading(false);
    } catch (e) {
      setErrorMessage("Failed to export orders");
      setShowError(true);
      setExportLoading(false);
    }
  };

  const openStatusChangeDialog = (order, statusId) => {
    setSelectedOrders([]);
    setCurrentOrder(order);
    setStatusDialogOpen(true);
  };

  const handleMultiSelect = useCallback(
    (selectedId) => {
      if (selectedOrders.includes(selectedId)) {
        setSelectedOrders(selectedOrders.filter((id) => id !== selectedId));
      } else {
        setSelectedOrders([...selectedOrders, selectedId]);
      }
    },
    [selectedOrders]
  );

  /**
   * Data to be passed to table
   */
  const adminTableData = {
    headers: [
      "Delivery Status",
      "Delivery Address",
      "Delivery Date",
      "Pickup address",
      "Pickup date",
      "Product",
      "Created At",
      "Size",
      "Description",
      "Carrier",
      "Tracking code",
      "Label",
    ],
    body: [
      (order) => (
        <Tooltip
          title={
            _.get(order, "statusHistory") ? (
              <div>
                {(_.get(order, "statusHistory") || []).map(
                  ({ name, remark, timestamp }) => (
                    <div>
                      <DateFormatter
                        date={timestamp}
                        format="dd/MM/yyyy-HH:mm"
                      />
                      {` ${name} ${remark}`}
                      <br />
                    </div>
                  )
                )}
              </div>
            ) : (
              ""
            )
          }
        >
          <Button
            endIcon={<EditIcon />}
            sx={styles.deliveryStatus}
            onClick={() => openStatusChangeDialog(order, order.status.id)}
          >
            <div
              style={
                order.status.name === "Confirmed"
                  ? {
                      padding: "7 5",
                      borderRadius: 20,
                      background: "#d7ecfa",
                      textAlign: "center",
                    }
                  : {
                      padding: "7 5",
                      borderRadius: 20,
                      background: "#ffd9d4",
                      textAlign: "center",
                    }
              }
            >
              {order.status.name}
            </div>
            {/* <Button
            // endIcon={<EditIcon />}
            onClick={() => openStatusChangeDialog(order, order.status.id)}
          >
            {order.status.name}
          </Button> */}
          </Button>
        </Tooltip>
      ),
      (order) =>
        formatAddress(
          order.deliveryAddress.postcode,
          order.deliveryAddress.houseNumber,
          order.deliveryAddress.houseAdditions,
          order.deliveryAddress.street
        ),
      (order) => (
        <DateFormatter date={order.deliveryDate} format="dd-MM-yyyy" />
      ),
      (order) =>
        formatAddress(
          order.pickupAddress.address.postcode,
          order.pickupAddress.address.houseNumber,
          order.pickupAddress.address.houseNumberAdditions,
          order.pickupAddress.address.street
        ),
      (order) => <DateFormatter date={order.pickupDate} format="dd-MM-yyyy" />,
      (order) => order.product.name,
      (order) => (
        <DateFormatter date={order.createdAt} format={"dd-MM-yyyy HH:mm"} />
      ),
      (order) =>
        order.size
          ? order.size.name
            ? order.size.name
            : "size is empty"
          : "size is empty",
      (order) => order.description,
      (order) => order.carrier.carrierName,
      (order) => order.trackingCode,
      (order) => (
        <ShippingLabelButton trackingCode={order.trackingCode} label="PDF" />
      ),
    ],
  };

  const onCloseErrorMessage = () => {
    setErrorMessage("");
    setShowError(false);
  };

  return (
    <MainBox>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showError}
        onClose={onCloseErrorMessage}
        autoHideDuration={TIME_SHOW_ERROR}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      {!prerequisites.isLoading && !prerequisites.error && (
        <UpdateOrderStatus
          statusDialogOpen={statusDialogOpen}
          handleStatusDialogClose={handleStatusDialogClose}
          setCurrentStatus={setCurrentStatus}
          currentStatus={currentStatus}
          statuses={prerequisites.data.statuses}
          saveStatus={saveStatus}
          showProgress={updatingStatus}
          status={currentStatus}
          reasons={prerequisites.data.reasons}
          setCurrentReason={setCurrentReason}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
      )}
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <div style={{ padding: 15 }}>
        {!prerequisites.isLoading && !prerequisites.error && (
          <Filters
            setTitle={"Scheduled"}
            locations={prerequisites.data.locations}
            selectedLocationsFilter={selectedLocationsFilter}
            setSelectedLocationsFilter={setSelectedLocationsFilter}
            selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
            setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
            selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
            setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
            onExportClick={onExportClick}
            exportLoading={exportLoading}
            selectedZipCodeFilter={selectedZipCodeFilter}
            setSelectedZipCodeFilter={setSelectedZipCodeFilter}
            onApplyFilter={updateTable}
          />
        )}
      </div>

      <TableComponent
        headers={adminTableData.headers}
        rowData={getFilteredOrders()}
        cellComponents={adminTableData.body}
        multiSelect
        selectedDeliveryDate={selectedDeliveryDate}
        selectedRows={selectedOrders}
        onSelect={handleMultiSelect}
        onMultiUpdate={() => setStatusDialogOpen(true)}
      />
    </MainBox>
  );
}

export default CarrierScheduled;
