import { IconScheduled, IconHistory } from "@shared/components/lib/index";
import CarrierScheduled from "./pages/CarrierScheduled";
import CarrierHistory from "./pages/CarrierHistory";
import Claims from "./pages/Claims";

export const getCarrierAdminPages = (props) => [
  {
    path: "/scheduled",
    name: "Scheduled",
    element: <CarrierScheduled userType={props.userType} />,
    icon: <IconScheduled color={"#fff"} />,
  },
  {
    path: "/history",
    name: "History",
    element: <CarrierHistory userType={props.userType} />,
    icon: <IconHistory color={"#fff"} />,
  },
  {
    path: "/claims",
    name: "Claims",
    element: <Claims />,
    icon: <IconScheduled color={"#fff"} />,
  },
];

export const getCarrierPages = (props) => [...getCarrierAdminPages(props)];
export const CARRIER_ADMIN_PAGES = [];

export const PAGES = [...CARRIER_ADMIN_PAGES];
