export const OTHER_META_DATA = {
  RETURN_TRACKING_CODE: "return_tracking_code",
  DROP_OFF_ID: "drop_off_id",
} as const;

export const CARRIER_META_DATA = {
  CARRIER_DIMENSIONS: "carrier_dimensions",
  CARRIER_VOLUME: "carrier_volume",
  CARRIER_WEIGHT: "carrier_weight",
  CARRIER_PACKAGE_TYPE: "carrier_package_type",
} as const;

export const COMMON_META_DATA = {
  ...CARRIER_META_DATA,
  ...OTHER_META_DATA,
} as const;
