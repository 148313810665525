import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import {
  CustomAppBar,
  ResetPassword,
  ForgotPassword,
  SignIn,
} from "@shared/components/lib/index";
import conf from "common-utils/conf";
import { getCarrierAdminPages, getCarrierPages } from "./routers";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI } from "common-utils/utils/api";

export default function CarrierApp() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();
  const [auth, setAuth] = useLocalStorage("auth", null);

  useEffect(() => {
    if (auth) {
      authUser();
    } else {
      cleanState();
    }
  }, [auth]);

  const signOut = async () => {
    await postRequestUI("/logout");
    cleanState();
    navigate("/login");
  };

  const cleanState = () => {
    setUserType(null);
    setAnchorEl(null);
    setAuth(null);
  };

  const authUser = async () => {
    try {
      const { role, email } = auth;
      if (!role.userType) {
        signOut();
        return;
      }
      if (role.userType !== "carrier" && role.userType !== "carrierAdmin") {
        signOut();
        return;
      }
      setUserType(role.userType);

      setTimeout(() => {
        window.Trengo.contact_data = {
          email: email,
          name: email,
          custom_fields: [
            {
              field_id: email,
              user_email: email,
            },
          ],
        };
        if (window.Trengo.Api && window.Trengo.Api.Widget) {
          window.Trengo.Api.Widget.render();
        }
      }, 2500);
    } catch (error) {
      signOut();
    }
  };

  const pages =
    userType === "carrier"
      ? getCarrierPages({ userType })
      : getCarrierAdminPages({ userType });

  return (
    <>
      {!auth && (
        <Routes>
          <Route path="/forgot-password/:token?" element={<ForgotPassword />} />
          <Route
            path="/reset-password/:token?"
            element={<ResetPassword appUrl={conf.carrier} />}
          />
          <Route path="/login" element={<SignIn />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
      {(userType === "carrier" || userType === "carrierAdmin") && (
        <>
          <CustomAppBar
            pages={pages}
            appName={"C"}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            mobileOpen={mobileOpen}
            setMobile={setMobileOpen}
            handleLogout={signOut}
          />
          <Routes>
            <Route path="/" element={<Navigate to="/scheduled" replace />} />
            {pages.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path="*" element={<Navigate to="/scheduled" replace />} />
          </Routes>
        </>
      )}
    </>
  );
}
