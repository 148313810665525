import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import "date-fns";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogContent from "@mui/material/DialogContent";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import FileDownload from "js-file-download";
import { formatAddress } from "common-utils/utils/formatter";
import useTheme from "@mui/material/styles/useTheme";
import { getRequestUI, postRequestUI } from "common-utils/utils/api";
import {
  Filters,
  TableComponent,
  MainBox,
  IconDropOf,
  IconPickUpRound,
  ShippingLabelButton,
  DateFormatter,
} from "@shared/components/lib/index";
import { Tooltip } from "@mui/material";
import { DateTime } from "luxon";

const useStyles = (theme) => ({
  formContainer: {
    height: "100%",
  },

  dialog: {
    maxWidth: 760,
    maxHeight: 373,
    margin: "180 auto auto",
    [theme.breakpoints.down("sm")]: {
      margin: "50 auto auto",
      maxHeight: 1000,
    },
  },
  dialogContent: {
    padding: "25",
    [theme.breakpoints.up("sm")]: {
      padding: "40 !important",
    },
  },
  containerItemsLeft: {
    borderRight: "1px solid #d6d6d6",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      borderBottom: "1px solid #d6d6d6",
      margin: "0 0 20px",
    },
  },
  containerItemsRight: {
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 0 40px",
    },
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
  },
  deliveryStatus: {
    display: "flex",
    alignItems: "center",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CarrierHistory(props) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date());
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(new Date());

  const [selectedLocationsFilter, setSelectedLocationsFilter] = useState([]);

  useEffect(() => {
    updateLocations();
    updateTable();
  }, []);

  const updateTable = () => {
    setShowProgress(true);
    getRequestUI("/carrier/history/orders", getFilterData())
      .then(function (result) {
        setShowProgress(false);
        setOrders(result);
      })
      .catch(function (error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateLocations = () => {
    return getRequestUI("/carrier/locations")
      .then((result) => {
        setLocations(result);
      })
      .catch((error) => {
        var code = error.code;
        var message = error.message;
      });
  };

  const getFilteredOrders = () => {
    return orders;
  };

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    locations: selectedLocationsFilter.map((location) => location.id),
  });

  const onExportClick = async () => {
    try {
      setExportLoading(true);
      const filters = getFilterData();
      const response = await postRequestUI("export-orders/carrier", filters);
      FileDownload(
        response,
        `orders_${filters.startDate}_${filters.endDate}.csv`
      );
      setExportLoading(false);
    } catch (e) {
      setErrorMessage("Failed to export orders");
      setShowError(true);
      setExportLoading(false);
      console.log(e);
    }
  };

  /**
   * Data to be passed to table
   */
  const adminTableData = {
    headers: [
      "Delivery Status",
      "Delivery Address",
      "Delivery Date",
      "Pickup address",
      "Pickup date",
      "Product",
      "Created At",
      "Size",
      "Description",
      "Carrier",
      "Tracking code",
      "Label",
    ],
    body: [
      (order) => (
        <Tooltip
          title={
            _.get(order, "statusHistory") ? (
              <div>
                {(_.get(order, "statusHistory") || []).map(
                  ({ name, remark, timestamp }) => (
                    <div>
                      <DateFormatter
                        date={timestamp}
                        format="dd/MM/yyyy-HH:mm"
                      />
                      {` ${name} ${remark}`}
                      <br />
                    </div>
                  )
                )}
              </div>
            ) : (
              ""
            )
          }
        >
          <div className={styles.deliveryStatus}>
            <div
              style={
                order.status.name === "Confirmed"
                  ? {
                      padding: "7 5",
                      borderRadius: 20,
                      background: "#d7ecfa",
                      textAlign: "center",
                    }
                  : {
                      padding: "7 5",
                      borderRadius: 20,
                      background: "#ffd9d4",
                      textAlign: "center",
                    }
              }
            >
              {order.status.name}
            </div>
            {/* <Button
            // endIcon={<EditIcon />}
            onClick={() => openStatusChangeDialog(order, order.status.id)}
          >
            {order.status.name}
          </Button> */}
          </div>
        </Tooltip>
      ),
      (order) =>
        formatAddress(
          order.deliveryAddress.postcode,
          order.deliveryAddress.houseNumber,
          order.deliveryAddress.houseAdditions,
          order.deliveryAddress.street
        ),
      (order) => (
        <DateFormatter date={order.deliveryDate} format="dd-MM-yyyy" />
      ),
      (order) =>
        formatAddress(
          order.pickupAddress.address.postcode,
          order.pickupAddress.address.houseNumber,
          order.pickupAddress.address.houseNumberAdditions,
          order.pickupAddress.address.street
        ),
      (order) => (
        <DateFormatter date={order.pickupDate} format={"dd-MM-yyyy"} />
      ),
      (order) => order.product.name,
      (order) => (
        <DateFormatter date={order.createdAt} format={"dd-MM-yyyy HH:mm"} />
      ),
      (order) =>
        order.size
          ? order.size.name
            ? order.size.name
            : "size is empty"
          : "size is empty",
      (order) => order.description,
      (order) => order.carrier.carrierName,
      (order) => order.trackingCode,
      (order) => (
        <ShippingLabelButton trackingCode={order.trackingCode} label="PDF" />
      ),
    ],
  };

  return (
    <MainBox>
      <Dialog
        open={open}
        fullScreen
        onClose={handleClose}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <DialogContent className={styles.dialogContent}>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Grid
            container
            item
            sm={12}
            justifyContent="space-between"
            className={styles.formContainer}
          >
            <Grid
              container
              item
              xs={12}
              sm={4}
              direction="column"
              justifyContent="space-between"
              className={styles.containerItemsLeft}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", fontSize: 16, fontWeight: 500 }}>
                  Created At:
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: 14,
                    marginBottom: 15,
                    color: "#a1a1a1",
                  }}
                >
                  Lorem
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", fontSize: 16, fontWeight: 500 }}>
                  Remarks:
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: 14,
                    marginBottom: 15,
                    color: "#a1a1a1",
                  }}
                >
                  Lorem Lorem
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", fontSize: 16, fontWeight: 500 }}>
                  Order Id:
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: 14,
                    marginBottom: 15,
                    color: "#a1a1a1",
                  }}
                >
                  Lorem LoremLorem
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", fontSize: 16, fontWeight: 500 }}>
                  Size:
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: 14,
                    marginBottom: 15,
                    color: "#a1a1a1",
                  }}
                >
                  Lorem Lorem
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", fontSize: 16, fontWeight: 500 }}>
                  Courier:
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: 14,
                    marginBottom: 15,
                    color: "#a1a1a1",
                  }}
                >
                  Lorem Lorem
                </div>
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={4}
              direction="column"
              className={styles.containerItemsRight}
            >
              <Grid container item>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 10 }}>
                    <IconPickUpRound />
                  </div>

                  <div>
                    <div style={{ fontSize: 16, fontWeight: 500 }}>Pick Up</div>
                    <div
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "#a1a1a1",
                      }}
                    >
                      16:00-17:00
                    </div>
                    <div style={{ fontSize: 14 }}>
                      Riyuals Leidsestraat(AMS)
                    </div>
                    <div style={{ fontSize: 14, color: "#a1a1a1" }}>
                      Boven Vredenburgpassage
                    </div>
                    <div style={{ fontSize: 14, color: "#a1a1a1" }}>
                      {" "}
                      1 3025M
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid container item style={{ paddingTop: 20 }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 10 }}>
                    <IconDropOf />
                  </div>
                  <div>
                    <div style={{ fontSize: 16, fontWeight: 500 }}>
                      Drop Off
                    </div>
                    <div
                      style={{
                        fontSize: 14,
                        marginBottom: 15,
                        color: "#a1a1a1",
                      }}
                    >
                      17:00-18:00
                    </div>
                    <div style={{ fontSize: 14 }}>Julia Lane</div>
                    <div style={{ fontSize: 14, color: "#a1a1a1" }}>
                      Gruttersdijk 1 3025M
                    </div>
                    <div style={{ fontSize: 14, color: "#a1a1a1" }}>
                      {" "}
                      1 3025M
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={4} justifyContent="flex-end">
              <div style={{ fontSize: 20, fontWeight: 500 }}>Rituals...</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <div style={{ padding: 20 }}>
        <Filters
          setTitle={"History"}
          locations={locations}
          selectedLocationsFilter={selectedLocationsFilter}
          setSelectedLocationsFilter={setSelectedLocationsFilter}
          selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
          setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
          selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
          setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
          onExportClick={onExportClick}
          exportLoading={exportLoading}
          onApplyFilter={updateTable}
        />
      </div>
      <TableComponent
        headers={adminTableData.headers}
        rowData={getFilteredOrders()}
        x
        cellComponents={adminTableData.body}
      />
    </MainBox>
  );
}
export default CarrierHistory;
